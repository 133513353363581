import React, { useState, useEffect } from "react";
import { parseISO, format } from "date-fns";
import { it } from "date-fns/locale";
import BranchSelector from "./BranchSelector";
import PrintTypeSelector from "./PrintTypeSelector";
import DevicesTypeSelector from "./DevicesTypeSelector";
import TaskModalDispositivi from "./TaskModalDispositivi";
import TaskModalPubblicitari from "./TaskModalPubblicitari";
import TaskModalWeb from "./TaskModalWeb";
import TasksModalMagazzino from "./TaskModalMagazzino";
import TagInput from "./TagInput";
import "../assets/css/ArchivedTask.css";

function ArchivedTasks({ archivedTasks }) {
  const [categoryFilter, setCategoryFilter] = useState("");
  const [branchFilter, setBranchFilter] = useState("");
  const [printTypeFilter, setPrintTypeFilter] = useState("Mostra Tutto");
  const [devicesTypeFilter, setDevicesTypeFilter] = useState("Mostra Tutto");
  const [sortOrder, setSortOrder] = useState("desc"); // Ordine cronologico (asc o desc)
  const [selectedTags, setSelectedTags] = useState([]); // Stato per i tag selezionati

  const [selectedTask, setSelectedTask] = useState(null); // Task selezionata
  const [isModalOpen, setIsModalOpen] = useState(false); // Stato della modale

  const openModal = (task) => {
    setSelectedTask(task);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedTask(null);
    setIsModalOpen(false);

    // Rimuovi eventuali classi residue dal body
    document.body.classList.remove("modal-open");
    const backdrop = document.querySelector(".modal-backdrop");
    if (backdrop) backdrop.remove(); // Rimuove l'overlay residuo
  };

  // Filtraggio delle task
  const filteredTasks = archivedTasks.filter((task) => {
    if (categoryFilter && task.category !== categoryFilter) return false;
    if (branchFilter && task.branch !== branchFilter) return false;
    if (
      printTypeFilter !== "Mostra Tutto" &&
      task.print_type !== printTypeFilter
    )
      return false;
    if (
      devicesTypeFilter !== "Mostra Tutto" &&
      task.devices_type !== devicesTypeFilter
    )
      return false;
    if (
      selectedTags.length > 0 &&
      !selectedTags.every((tag) => task.tags?.includes(tag))
    )
      return false;
    return true;
  });

  // Ordinamento delle task
  const sortedTasks = [...filteredTasks].sort((a, b) => {
    const dateA = a.archived_at ? new Date(a.archived_at) : new Date(0);
    const dateB = b.archived_at ? new Date(b.archived_at) : new Date(0);
    return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
  });

  const toggleSortOrder = () => {
    setSortOrder((prev) => (prev === "asc" ? "desc" : "asc"));
  };

  const resetFilters = () => {
    setCategoryFilter("");
    setBranchFilter("");
    setPrintTypeFilter("Mostra Tutto");
    setDevicesTypeFilter("Mostra Tutto");
    setSelectedTags([]);
  };

  useEffect(() => {
    if (!isModalOpen) {
      // Rimuove l'overlay residuo quando la modale è chiusa
      const backdrop = document.querySelector(".modal-backdrop");
      if (backdrop) backdrop.remove();
    }
  }, [isModalOpen]);

  return (
    <div className="main-container mb-5">
      <h3>Archivio Task</h3>

      {/* Filtri */}
      <div className="filters mb-4">
        <div className="row g-3">
          {/* Prima riga */}
          <div className="col-md-3">
            <label htmlFor="category" className="form-label">
              Categoria
            </label>
            <select
              id="category"
              className="form-select"
              value={categoryFilter}
              onChange={(e) => setCategoryFilter(e.target.value)}
            >
              <option value="">Tutte</option>
              <option value="Pubblicitari">Pubblicitari</option>
              <option value="Dispositivi">Dispositivi</option>
              <option value="Magazzino">Magazzino</option>
              <option value="Web">Web</option>
            </select>
          </div>
          <div className="col-md-3">
            <BranchSelector value={branchFilter} onChange={setBranchFilter} />
          </div>
          <div className="col-md-3">
            <PrintTypeSelector
              value={printTypeFilter}
              onChange={setPrintTypeFilter}
            />
          </div>
          <div className="col-md-3">
            <DevicesTypeSelector
              value={devicesTypeFilter}
              onChange={setDevicesTypeFilter}
            />
          </div>

          {/* Seconda riga per il filtro Tag */}
          <div className="col-md-12">
            <TagInput
              selectedTags={selectedTags}
              onTagsChange={(tags) => setSelectedTags(tags)}
              
            />
          </div>
        </div>

        {/* Bottoni per Resetta Filtri e Ordina */}
        <div className="mt-3">
          <button className="btn btn-secondary me-2" onClick={resetFilters}>
            Resetta Filtri
          </button>
          <button className="btn btn-link" onClick={toggleSortOrder}>
            Ordina {sortOrder === "asc" ? "↑" : "↓"}
          </button>
        </div>
      </div>

      {/* Task filtrate */}
      {sortedTasks.length > 0 ? (
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Branch</th>
              <th>Data di Archiviazione</th>
              <th>Azioni</th>
            </tr>
          </thead>
          <tbody>
            {sortedTasks.map((task) => (
              <tr key={task.id}>
                <td>{task.title}</td>
                <td>{task.branch || "N/D"}</td>
                <td>
                  {task.archived_at
                    ? format(parseISO(task.archived_at), "dd MMMM yyyy", {
                        locale: it,
                      })
                    : "N/D"}
                </td>
                <td>
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => openModal(task)}
                  >
                    Visualizza
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className="text-center">
          Nessuna task trovata con i filtri attuali.
        </p>
      )}

      {/* Modale per i dettagli della task */}
      {isModalOpen && selectedTask && (
        <>
          {selectedTask.category === "Dispositivi" && (
            <TaskModalDispositivi
              taskToEdit={selectedTask}
              isOpen={isModalOpen}
              onClose={closeModal}
              readOnly={true} // Modale di sola lettura
            />
          )}
          {selectedTask.category === "Pubblicitari" && (
            <TaskModalPubblicitari
              taskToEdit={selectedTask}
              isOpen={isModalOpen}
              onClose={closeModal}
              readOnly={true} // Modale di sola lettura
            />
          )}
          {selectedTask.category === "Web" && (
            <TaskModalWeb
              taskToEdit={selectedTask}
              isOpen={isModalOpen}
              onClose={closeModal}
              readOnly={true} // Modale di sola lettura
            />
          )}
          {selectedTask.category === "Magazzino" && (
            <TasksModalMagazzino
              taskToEdit={selectedTask}
              isOpen={isModalOpen}
              onClose={closeModal}
              readOnly={true} // Modale di sola lettura
            />
          )}
        </>
      )}
    </div>
  );
}

export default ArchivedTasks;

import React, { useState, useEffect } from 'react';
import NavigationLayout from './components/NavigationLayout';
import TaskBoard from './components/TaskBoard';
import UrgentMessages from './components/UrgentMessages';
import ProductList from './components/ProductList';
import ArchivedTasks from './components/ArchivedTasks';
import ArchivedProducts from './components/ArchivedProducts';
import { UserProvider } from "./components/UserContext";
import './App.css';
// @ts-ignore
import { createIcons } from 'duo-icons';

const MainApp = () => {
  const [archivedTasks, setArchivedTasks] = useState([]);
  const [archivedProducts, setArchivedProducts] = useState([]);
  const [view, setView] = useState('taskPubblicitari');
  const [subView, setSubView] = useState('archivioTasks');

  const handleViewChange = (newView) => {
    setView(newView);
  };

  useEffect(() => {
    try {
      console.log('Initializing duo-icons...');
      createIcons(); // Chiamata per inizializzare le icone
      console.log('Icons initialized successfully.');
    } catch (error) {
      console.error('Error initializing duo-icons:', error);
    }
  }, []); // Assicura che venga chiamato solo una volta

  useEffect(() => {
    if (view === 'archivio') {
      // Fetch archivio tasks
      fetch(`${process.env.REACT_APP_API_BASE_URL}/api/tasks?archived=true`)
        .then((response) => {
          if (!response.ok) throw new Error('Errore nel caricamento delle task archiviate');
          return response.json();
        })
        .then((data) => setArchivedTasks(data))
        .catch((error) => console.error('Errore nel caricamento delle task archiviate:', error));
  
      // Fetch archivio products
      fetch(`${process.env.REACT_APP_API_BASE_URL}/api/products?archived=true`)
        .then((response) => {
          if (!response.ok) throw new Error('Errore nel caricamento dei prodotti archiviati');
          return response.json();
        })
        .then((data) => setArchivedProducts(data))
        .catch((error) => console.error('Errore nel caricamento dei prodotti archiviati:', error));
    }
  }, [view]);

  return (
    <UserProvider>
      <div>
        <NavigationLayout currentView={view} onSelectView={handleViewChange} />
        <div className="container-fluid">
          <div className="row">
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              {view === 'taskPubblicitari' && (
                <TaskBoard category="Pubblicitari" setArchivedTasks={setArchivedTasks} />
              )}
              {view === 'taskDispositivi' && (
                <TaskBoard category="Dispositivi" setArchivedTasks={setArchivedTasks} />
              )}
              {view === 'taskWeb' && (
                <TaskBoard category="Web" setArchivedTasks={setArchivedTasks} />
              )}
              {view === 'taskMagazzino' && (
                <TaskBoard category="Magazzino" setArchivedTasks={setArchivedTasks} />
              )}
              {view === 'messaggi' && <UrgentMessages />}
              {view === 'magazzino' && <ProductList />}
              {view === 'archivio' && (
                <div className="container mt-4">
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <button
                        className={`nav-link ${subView === 'archivioTasks' ? 'active' : ''}`}
                        onClick={() => setSubView('archivioTasks')}
                      >
                        Archivio Task
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={`nav-link ${subView === 'archivioProducts' ? 'active' : ''}`}
                        onClick={() => setSubView('archivioProducts')}
                      >
                        Archivio Prodotti
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content">
                    {subView === 'archivioTasks' && <ArchivedTasks archivedTasks={archivedTasks} />}
                    {subView === 'archivioProducts' && (
                      <ArchivedProducts archivedProducts={archivedProducts} />
                    )}
                  </div>
                </div>
              )}
            </main>
          </div>
        </div>
      </div>
    </UserProvider>
  );
};

export default MainApp;
import React, { useState } from 'react';

function NavigationLayout({ currentView, onSelectView }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div>
      {/* Navbar */}
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <i data-duoicon="app"></i>
          <a className="navbar-brand" href="index.html">Bacheca Roydermal</a>
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleSidebar} // Controllo del toggle
            aria-controls="sidebarMenu"
            aria-expanded={isSidebarOpen}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav me-auto">
              <li className="nav-item">
                {/* Eventuali pulsanti o elementi aggiuntivi */}
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/* Sidebar */}
<nav
  id="sidebarMenu"
  className={`sidebar ${isSidebarOpen ? 'show' : ''} d-md-block`}
>
  <div className="position-sticky pt-3">
    <ul className="nav flex-column">
      {/* Task Section */}
      <li className="nav-item">
        <span className="nav-link" aria-current="page">
          <i data-duoicon="clipboard"></i>
          <strong>Task</strong>
        </span>
      </li>
      <li className="nav-item">
        <button
          className={`nav-link ${currentView === 'taskPubblicitari' ? 'active' : ''}`}
          onClick={() => {
            onSelectView('taskPubblicitari');
            setIsSidebarOpen(false); // Chiudi la sidebar
          }}
        >
          Pubblicitari
        </button>
      </li>
      <li className="nav-item">
        <button
          className={`nav-link ${currentView === 'taskDispositivi' ? 'active' : ''}`}
          onClick={() => {
            onSelectView('taskDispositivi');
            setIsSidebarOpen(false); // Chiudi la sidebar
          }}
        >
          Dispositivi
        </button>
      </li>
      <li className="nav-item">
        <button
          className={`nav-link ${currentView === 'taskWeb' ? 'active' : ''}`}
          onClick={() => {
            onSelectView('taskWeb');
            setIsSidebarOpen(false); // Chiudi la sidebar
          }}
        >
          Web
        </button>
      </li>
      <li className="nav-item">
        <button
          className={`nav-link ${currentView === 'taskMagazzino' ? 'active' : ''}`}
          onClick={() => {
            onSelectView('taskMagazzino');
            setIsSidebarOpen(false); // Chiudi la sidebar
          }}
        >
          Magazzino
        </button>
      </li>

      {/* Messaggi Section */}
      <li className="nav-item mt-3">
        <button
          className={`nav-link ${currentView === 'messaggi' ? 'active' : ''}`}
          onClick={() => {
            onSelectView('messaggi');
            setIsSidebarOpen(false); // Chiudi la sidebar
          }}
        >
          <i data-duoicon="message"></i>
          <strong>Messaggi</strong>
        </button>
      </li>

      {/* Magazzino Section */}
      <li className="nav-item mt-3">
        <button
          className={`nav-link ${currentView === 'magazzino' ? 'active' : ''}`}
          onClick={() => {
            onSelectView('magazzino');
            setIsSidebarOpen(false); // Chiudi la sidebar
          }}
        >
          <i data-duoicon="box-2"></i>
          <strong>Prodotti</strong>
        </button>
      </li>

      {/* Archivio Section */}
      <li className="nav-item mt-3">
        <button
          className={`nav-link ${currentView === 'archivio' ? 'active' : ''}`}
          onClick={() => {
            onSelectView('archivio');
            setIsSidebarOpen(false); // Chiudi la sidebar
          }}
        >
          <i data-duoicon="box"></i>
          <strong>Archivio</strong>
        </button>
      </li>
    </ul>
  </div>
</nav>
    </div>
  );
}

export default NavigationLayout;

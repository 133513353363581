import React from 'react';

function BranchSelector({ value, onChange, disabled }) {
  const branches = ['Mostra Tutto', 'Roydermal', 'Roypet', 'Hevalight', 'MgGroup']; // Aggiunto "Mostra Tutto"

  return (
    <div className="mb-3">
      <label htmlFor="branch" className="form-label">Seleziona Branch</label>
      <select
        id="branch"
        className="form-select"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled} // Rendi il selettore non modificabile
      >
        {branches.map((branch) => (
          <option
            key={branch}
            value={branch === 'Mostra Tutto' ? '' : branch} // "Mostra Tutto" usa un valore vuoto
          >
            {branch}
          </option>
        ))}
      </select>
    </div>
  );
}

export default BranchSelector;
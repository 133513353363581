import React, { useState, useEffect } from 'react';
import PasswordPrompt from './components/PasswordPrompt';
import MainApp from './MainApp';

const SESSION_TIMEOUT_MS = 30 * 60 * 1000; // Timeout di 30 minuti

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Controlla se l'utente è già autenticato
    const sessionStartTime = sessionStorage.getItem('sessionStartTime');
    const currentTime = new Date().getTime();

    if (sessionStartTime && currentTime - sessionStartTime < SESSION_TIMEOUT_MS) {
      setIsAuthenticated(true);
    }
  }, []);

  const handlePasswordCorrect = () => {
    setIsAuthenticated(true);
    sessionStorage.setItem('sessionStartTime', new Date().getTime());
  };

  if (!isAuthenticated) {
    return <PasswordPrompt onPasswordCorrect={handlePasswordCorrect} />;
  }

  return <MainApp />;
};

export default App;
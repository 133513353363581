import React, { useState, useEffect, useRef } from 'react';
import Modal from 'bootstrap/js/dist/modal';

function UrgentMessageModal({ isOpen, onClose, addMessage }) {
  const [messageContent, setMessageContent] = useState('');
  const [pinMessage, setPinMessage] = useState(false);

  const modalRef = useRef(null); // Riferimento alla modale
  const bsModal = useRef(null); // Istanza di Bootstrap Modal

  // Inizializza la modale quando il componente viene montato
  useEffect(() => {
    if (modalRef.current) {
      bsModal.current = new Modal(modalRef.current, {
        backdrop: 'static',
        keyboard: false,
      });
    }
  }, []);

  // Mostra o nasconde la modale in base a isOpen
  useEffect(() => {
    if (isOpen && bsModal.current) {
      bsModal.current.show();
    } else if (bsModal.current) {
      bsModal.current.hide();
    }
  }, [isOpen]);

  const handleSubmit = (e) => {
    e.preventDefault();
    addMessage({
      id: Date.now(),
      message: messageContent,
      pinned: pinMessage,
      read: false,
    });
    setMessageContent('');
    setPinMessage(false);
    onClose(); // Chiude la modale
  };

  return (
    <div
      className="modal fade"
      id="urgentMessageModal"
      tabIndex="-1"
      aria-labelledby="urgentMessageModalLabel"
      aria-hidden={!isOpen}
      ref={modalRef}
    >
      <div className="modal-dialog">
        <form onSubmit={handleSubmit}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="urgentMessageModalLabel">Aggiungi Comunicazione Urgente</h5>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={onClose}
              ></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label htmlFor="urgentMessageContent" className="form-label">Messaggio</label>
                <textarea
                  className="form-control"
                  id="urgentMessageContent"
                  rows="3"
                  value={messageContent}
                  onChange={(e) => setMessageContent(e.target.value)}
                  required
                ></textarea>
              </div>
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="pinMessage"
                  checked={pinMessage}
                  onChange={(e) => setPinMessage(e.target.checked)}
                />
                <label className="form-check-label" htmlFor="pinMessage">Pinna il messaggio</label>
              </div>
              <button type="submit" className="btn btn-primary">Aggiungi</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default UrgentMessageModal;

import React, { createContext, useContext, useState } from "react";

// Create the context
const UserContext = createContext();

// Provider component
export const UserProvider = ({ children }) => {
  const [users] = useState([
    { name: "Mariateresa", email: "mtcirillo@roydermal.it", group: "Gruppo Web", isGroup: false },
    { name: "Pasquale", email: "malvone@roydermal.it", group: "Gruppo Grafica", isGroup: false },
    { name: "Antonio", email: "antoniodirosa@roydermal.it", group: "Gruppo Grafica", isGroup: false },
    { name: "Alessio", email: "magazzino@roydermal.it", group: "Gruppo Magazzino", isGroup: false },
    { name: "Ferdinando", email: "ferdinando@roydermal.it", group: "Gruppo Magazzino", isGroup: false },
    { name: "Enzo", email: "admin@roydermal.it", group: "Gruppo Web", isGroup: false },
    { name: "Gruppo Grafica", email: "antoniodirosa@roydermal.it, malvone@roydermal.it", group: "Gruppo Grafica", isGroup: true },
    { name: "Gruppo Web", email: "admin@roydermal.it, mtcirillo@roydermal.it", group: "Gruppo Web", isGroup: true },
    { name: "Magazzino", email: "magazzino@roydermal.it", group: "Gruppo Magazzino", isGroup: true },
  ]);

  // Group the users
  const groupedUsers = users.reduce(
    (acc, user) => {
      const group = user.group || "Tutti";
      if (!acc[group]) acc[group] = [];
      acc[group].push(user);
      return acc;
    },
    {
      "Tutti": [],
      "Gruppo Grafica": [],
      "Gruppo Web": [],
      "Gruppo Magazzino": [],
    }
  );

  // Populate the "Tutti" group with individual users
  groupedUsers["Tutti"] = users.filter(user => !user.isGroup);

  return (
    <UserContext.Provider value={{ users, groupedUsers }}>
      {children}
    </UserContext.Provider>
  );
};

// Hook to access the context
export const useUsers = () => {
  return useContext(UserContext);
};
import React, { useState, useEffect } from 'react';
import BranchSelector from './BranchSelector';
import DevicesTypeSelector from './DevicesTypeSelector';
import TagInput from './TagInput';
import ProductModal from './ProductModal';

function ProductList() {
  const [products, setProducts] = useState([]);
  const [archivedProducts, setArchivedProducts] = useState([]);
  const [filterBranch, setFilterBranch] = useState('');
  const [filterDevicesType, setFilterDevicesType] = useState('');
  const [selectedFilterTags, setSelectedFilterTags] = useState([]);
  const [tasksInPrint, setTasksInPrint] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [productToEdit, setProductToEdit] = useState(null);

  // Fetch iniziale dei prodotti
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/products`)
      .then((response) => response.json())
      .then((data) => {
        setProducts(data.filter((product) => !product.archived));
        setArchivedProducts(data.filter((product) => product.archived));
      })
      .catch((error) => console.error('Errore durante il fetch dei prodotti:', error));
  }, []);

  // Fetch delle task dispositivi "in stampa"
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/api/tasks?status=in-print&category=Dispositivi&archived=false`
    )
      .then((response) => response.json())
      .then((data) => setTasksInPrint(data))
      .catch((error) => console.error('Errore durante il fetch delle task in stampa:', error));
  }, []);

  // Filtro prodotti per tag, branch e devicesType
  const filteredProducts = products.filter((product) => {
    const hasAllTags =
      selectedFilterTags.length === 0 ||
      selectedFilterTags.every((tag) => product.tags?.includes(tag));

    const matchesBranch =
      !filterBranch || filterBranch === 'Mostra Tutto' || product.branch === filterBranch;

    const matchesDevicesType =
      !filterDevicesType ||
      filterDevicesType === 'Mostra Tutto' ||
      product.devices_type === filterDevicesType;

    return hasAllTags && matchesBranch && matchesDevicesType;
  });

  const handleArchiveProduct = (productId) => {
    const productToArchive = products.find((product) => product.id === productId);
    const updatedProducts = products.filter((product) => product.id !== productId);
    setProducts(updatedProducts);
    setArchivedProducts([...archivedProducts, { ...productToArchive, archived: true }]);

    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/products/${productId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ archived: true }),
    }).catch((error) => console.error('Errore durante l\'archiviazione del prodotto:', error));
  };

  const handleSaveProduct = (productData) => {
    if (productData.id) {
      // Modifica prodotto esistente
      setProducts((prev) =>
        prev.map((product) => (product.id === productData.id ? productData : product))
      );
    } else {
      // Aggiunta nuovo prodotto
      const newProduct = { ...productData, id: Date.now(), archived: false };
      setProducts((prev) => [...prev, newProduct]);
    }
    setIsModalOpen(false);
  };

  const openModalForEdit = (product) => {
    setProductToEdit(product);
    setIsModalOpen(true);
  };

  const handleStatusChange = (productId, newStatus) => {
    const updatedProducts = products.map((product) =>
      product.id === productId ? { ...product, status: newStatus } : product
    );

    setProducts(updatedProducts);

    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/products/${productId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ status: newStatus }),
    }).catch((error) => console.error('Errore durante l\'aggiornamento dello stato:', error));
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/D';
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('it-IT', options);
  };

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h3>Prodotti</h3>
        <button
          className="btn btn-primary"
          onClick={() => {
            setProductToEdit(null);
            setIsModalOpen(true);
          }}
        >
          Aggiungi Prodotto
        </button>
      </div>

      {/* Filtri */}
      <div className="row g-3 mb-3">
        <div className="col-md-4">
          <BranchSelector value={filterBranch} onChange={setFilterBranch} />
        </div>
        <div className="col-md-4">
          <DevicesTypeSelector value={filterDevicesType} onChange={setFilterDevicesType} />
        </div>
        <div className="col-md-4">
          <TagInput selectedTags={selectedFilterTags} onTagsChange={setSelectedFilterTags} />
        </div>
      </div>

      {/* Lista Prodotti */}
      <h5>Lista Prodotti Attivi</h5>
      <table className="table table-striped mt-2">
        <thead>
          <tr>
            <th>Nome</th>
            <th>Data di Ordine</th>
            <th>Cambio INCI</th>
            <th>Cambio Grafica</th>
            <th>Stato</th>
            <th>Tag</th>
            <th>Azioni</th>
          </tr>
        </thead>
        <tbody>
          {filteredProducts.map((product) => (
            <tr key={product.id}>
              <td>{product.name}</td>
              <td>{formatDate(product.releaseDate)}</td>
              <td>{product.inci_change ? <span>&#10004;</span> : <span>&#10008;</span>}</td>
              <td>{product.graphic_change ? <span>&#10004;</span> : <span>&#10008;</span>}</td>
              <td>
                <select
                  className="form-select form-select-sm"
                  value={product.status}
                  onChange={(e) => handleStatusChange(product.id, e.target.value)}
                >
                  <option value="Ordinato">Ordinato</option>
                  <option value="In consegna">In consegna</option>
                  <option value="Ricevuto">Ricevuto</option>
                </select>
              </td>
              <td>{product.tags?.join(', ') || 'Nessun tag'}</td>
              <td>
                <button
                  className="btn btn-sm btn-warning me-2"
                  onClick={() => openModalForEdit(product)}
                >
                  Modifica
                </button>
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() => handleArchiveProduct(product.id)}
                >
                  Archivia
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Modale */}
      <ProductModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={handleSaveProduct}
        productData={productToEdit}
        tasksInPrint={tasksInPrint}
      />
    </div>
  );
}

export default ProductList;

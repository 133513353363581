import React from 'react';

function PrintTypeSelector({ value, onChange, disabled, isModal = false }) {
  const printTypes = [
    'Mostra Tutto',
    'A3',
    'Banner',
    'Box',
    'Broshure',
    'Cartolina',
    'Catalogo',
    'Depliant',
    'Espositore',
    'Folder',
    'Focus',
    'Gadget',
    'Invito',
    'Manuale',
    'Offerte commerciali',
    'Pannello',
    'Pieghevole',
    'Prontuario',
    'Proposte impianti',
    'Segnalibri',
    'Altro',
  ];

  const getDisplayText = (type) => {
    if (isModal && type === 'Mostra Tutto') return 'Seleziona'; // Nella modale mostra "Seleziona"
    return type; // Altrimenti mostra il valore normale
  };

  return (
    <div className="mb-3">
      <label htmlFor="printTypeSelector" className="form-label">Seleziona Stampa</label>
      <select
        id="printTypeSelector"
        className="form-select"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled} // Rendi il selettore non modificabile
      >
        {printTypes.map((type) => (
          <option key={type} value={type}>
            {getDisplayText(type)}
          </option>
        ))}
      </select>
    </div>
  );
}

export default PrintTypeSelector;

import React, { useState, useEffect, useCallback } from 'react';
import TaskModalPubblicitari from './TaskModalPubblicitari';
import TaskModalDispositivi from './TaskModalDispositivi';
import TaskModalWeb from './TaskModalWeb';
import TaskModalMagazzino from './TaskModalMagazzino';
import TaskTable from './TaskTable';
import PrintTypeSelector from './PrintTypeSelector';
import DevicesTypeSelector from './DevicesTypeSelector';
import { createIcons } from 'duo-icons';
import _ from 'lodash';

function TaskBoard({ category, setArchivedTasks }) {
  const [tasks, setTasks] = useState([]);
  const [taskToEdit, setTaskToEdit] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState('');
  const [selectedPrintType, setSelectedPrintType] = useState('Mostra Tutto'); // Default
  const [selectedDevicesType, setSelectedDevicesType] = useState('Mostra Tutto');
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

  // Funzione per processare le task
  const processTask = (task) => ({
    ...task,
    print: Boolean(task.print),
    print_status: task.print_status || '',
    print_type: task.print_type || 'Mostra Tutto', // Gestione default
    devices_type: task.devices_type || 'Mostra Tutto'
  });
  
  const handleStatusChange = (taskId, newStatus) => {
    const updatedTasks = tasks.map(task =>
      task.id === taskId ? { ...task, status: newStatus } : task
    );
  
    // Aggiorna lo stato locale con la lista di task aggiornata
    setTasks(updatedTasks);
  
    // Aggiorna lo stato della task nel server
    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/tasks/${taskId}/status`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ status: newStatus }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Errore durante l\'aggiornamento dello stato');
        }
      })
      .catch(error => {
        console.error('Errore durante l\'aggiornamento dello stato:', error);
  
        // In caso di errore, ripristina lo stato precedente
        setTasks(tasks);
      });
  };

  const handlePrintStatusChange = async (taskId, newStatus) => {
    const updatedTasks = tasks.map((task) =>
      task.id === taskId ? { ...task, print_status: newStatus } : task
    );
  
    setTasks(updatedTasks); // Aggiorna lo stato locale prima del fetch
  
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/tasks/${taskId}/print-status`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ print_status: newStatus }),
        }
      );
  
      if (!response.ok) {
        throw new Error('Errore durante l\'aggiornamento dello stato di stampa');
      }
    } catch (error) {
      console.error('Errore durante l\'aggiornamento dello stato di stampa:', error);
  
      // Ripristina lo stato precedente in caso di errore
      setTasks(tasks);
    }
  };


  // Recupero delle task dal server
  const fetchTasks = useCallback(async () => {
    try {
      const branchFilter = selectedBranch ? `&branch=${selectedBranch}` : '';
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/tasks?category=${category}&archived=false${branchFilter}`
      );
  
      if (!response.ok) throw new Error('Errore nel caricamento delle task');
      const data = await response.json();
      const processedTasks = data.map(processTask);
  
      setTasks(processedTasks);
      setArchivedTasks(processedTasks.filter((task) => task.archived)); // Filtra task archiviate
    } catch (error) {
      console.error('Errore nel caricamento delle task:', error);
    }
  }, [category, selectedBranch, setArchivedTasks]);
  

  useEffect(() => {
    try {
      createIcons();
    } catch (error) {
      console.error('Errore durante l\'inizializzazione delle icone:', error);
    }
  }, [category]);

  useEffect(() => {
    fetchTasks();
  }, [fetchTasks]);

  // Filtra le task in base a branch e print_type
  const filteredTasks = _.filter(tasks, (task) => {
    if (selectedBranch && task.branch !== selectedBranch) {
      return false; // Filtra per branch
    }
  
    if (
      category === 'Pubblicitari' && 
      selectedPrintType !== 'Mostra Tutto' && // Ignora filtro avanzato se "Mostra Tutto"
      task.print_type !== selectedPrintType
    ) {
      return false; // Filtra per print_type
    }
  
    if (
      category === 'Dispositivi' &&
      selectedDevicesType !== 'Mostra Tutto' && // Ignora filtro avanzato se "Mostra Tutto"
      task.devices_type !== selectedDevicesType
    ) {
      return false; // Filtra per devices_type
    }
  
    return true; // Altrimenti, includi la task
  });
  


  


  // Funzioni per aggiornare le task
  const handleTaskAdded = (newTask) => setTasks((prevTasks) => [...prevTasks, processTask(newTask)]);
  const handleTaskUpdated = (updatedTask) =>
    setTasks((prevTasks) => prevTasks.map((task) => (task.id === updatedTask.id ? processTask(updatedTask) : task)));
  const handleTaskDeleted = (taskId) =>
    setTasks((prevTasks) => prevTasks.filter((task) => task.id !== taskId));

  const archiveTask = async (taskId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/tasks/${taskId}/archive`,
        {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
        }
      );
  
      if (!response.ok) throw new Error('Errore durante l\'archiviazione della task');
      const archivedTask = tasks.find((task) => task.id === taskId);
      setTasks((prevTasks) => prevTasks.filter((task) => task.id !== taskId));
      setArchivedTasks((prev) => [...prev, archivedTask]);
    } catch (error) {
      console.error('Errore durante l\'archiviazione della task:', error);
    }
  };

  const toggleAdvancedFilters = () => {
    setShowAdvancedFilters((prev) => !prev);
  
    if (showAdvancedFilters) {
      switch (category) {
        case 'Pubblicitari':
          setSelectedPrintType('Mostra Tutto');
          break;
        case 'Dispositivi':
          setSelectedDevicesType('Mostra Tutto');
          break;
        // Aggiungi altre categorie se necessario
        default:
          break;
      }
    }
  };
  

  const renderTaskModal = () => {
    const modalProps = {
      isOpen: isModalOpen,
      onClose: () => {
        setIsModalOpen(false);
        setTaskToEdit(null);
      },
      taskToEdit,
      onTaskAdded: handleTaskAdded,
      onTaskUpdated: handleTaskUpdated,
      onTaskDeleted: handleTaskDeleted,
    };

    switch (category) {
      case 'Pubblicitari':
        return <TaskModalPubblicitari {...modalProps} />;
      case 'Dispositivi':
        return <TaskModalDispositivi {...modalProps} />;
      case 'Web':
        return <TaskModalWeb {...modalProps} />;
      case 'Magazzino':
        return <TaskModalMagazzino {...modalProps} />;
      default:
        return null;
    }
  };

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h4>{category}</h4>
        <button
          className="btn-action btn btn-primary"
          onClick={() => setIsModalOpen(true)}
        >
          <i data-duoicon="add-circle"></i>
          <span style={{ paddingLeft: '10px' }}>Aggiungi Attività</span>
        </button>
      </div>

      {/* Branch Selector */}
      <div className="mb-3">
        <label htmlFor="branchSelector" className="form-label">Filtra per Branch</label>
        <select
          id="branchSelector"
          className="form-select"
          value={selectedBranch}
          onChange={(e) => setSelectedBranch(e.target.value)}
        >
          <option value="">Mostra Tutto</option>
          <option value="Roydermal">Roydermal</option>
          <option value="Roypet">Roypet</option>
          <option value="Hevalight">Hevalight</option>
          <option value="MgGroup">MgGroup</option>
        </select>
      </div>

      {/* Advanced Filters for Pubblicitari */}
      {category === 'Pubblicitari' && (
        <>
          <button
            className="btn btn-secondary mb-3"
            onClick={toggleAdvancedFilters}
          >
            {showAdvancedFilters ? 'Nascondi Filtri Avanzati' : 'Mostra Filtri Avanzati'}
          </button>
          {showAdvancedFilters && (
            <PrintTypeSelector
              value={selectedPrintType}
              onChange={(value) => setSelectedPrintType(value)}
            />
          )}
        </>
      )}

      {/* Advanced Filters for Dispositivi */}
      {category === 'Dispositivi' && (
        <>
          <button
            className="btn btn-secondary mb-3"
            onClick={toggleAdvancedFilters}
          >
            {showAdvancedFilters ? 'Nascondi Filtri Avanzati' : 'Mostra Filtri Avanzati'}
          </button>
          {showAdvancedFilters && (
            <DevicesTypeSelector
              value={selectedDevicesType}
              onChange={(value) => setSelectedDevicesType(value)}
            />
          )}
        </>
      )}

      {/* Task Tables */}
      {['todo', 'in-progress', 'in-print', 'done']
        .filter((status) => !((category === 'Web' || category === 'Magazzino') && status === 'in-print'))
        .map((status) => (
          <TaskTable
            key={status}
            title={statusMap[status]}
            tasks={filteredTasks.filter((task) => task.status === status)}
            onEdit={(task) => {
              setTaskToEdit(task);
              setIsModalOpen(true);
            }}
            onArchive={archiveTask}
            onStatusChange={handleStatusChange}
            onPrintStatusChange={handlePrintStatusChange}
            activeCategory={category}
          />
        ))}

      {renderTaskModal()}
    </div>
  );
}




const statusMap = {
  todo: 'Da Fare',
  'in-progress': 'In Corso',
  'in-print': 'In Stampa',
  done: 'Completato',
};

export default TaskBoard;

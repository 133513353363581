import React, { useState, useEffect } from 'react';
import BranchSelector from './BranchSelector';
import DevicesTypeSelector from './DevicesTypeSelector';
import TagInput from './TagInput';

function ProductModal({ isOpen, onClose, onSave, productData, tasksInPrint, readOnly }) {
  const [productName, setProductName] = useState('');
  const [productReleaseDate, setProductReleaseDate] = useState('');
  const [tags, setTags] = useState([]);
  const [branch, setBranch] = useState('Roydermal');
  const [devicesType, setDevicesType] = useState('Mostra Tutto');
  const [refNumber, setRefNumber] = useState('');
  const [selectedTask, setSelectedTask] = useState(null);
  const [inciChange, setInciChange] = useState(false);
  const [graphicChange, setGraphicChange] = useState(false);
  const [inciNotes, setInciNotes] = useState('');
  const [graphicNotes, setGraphicNotes] = useState('');

  // Inizializza i campi quando i dati del prodotto cambiano
  useEffect(() => {
    if (productData) {
      setProductName(productData.name || '');
      setProductReleaseDate(productData.releaseDate || '');
      setTags(productData.tags || []);
      setBranch(productData.branch || 'Roydermal');
      setDevicesType(productData.devices_type || 'Mostra Tutto');
      setRefNumber(productData.ref_number || '');
      setInciChange(productData.inci_change || false);
      setGraphicChange(productData.graphic_change || false);
      setInciNotes(productData.inci_notes || '');
      setGraphicNotes(productData.graphic_notes || '');
    } else {
      // Reset dei campi
      setProductName('');
      setProductReleaseDate('');
      setTags([]);
      setBranch('Roydermal');
      setDevicesType('Mostra Tutto');
      setRefNumber('');
      setInciChange(false);
      setGraphicChange(false);
      setInciNotes('');
      setGraphicNotes('');
    }
  }, [productData]);

  const handleTaskSelect = (taskId) => {
    const selected = tasksInPrint.find((task) => task.id === parseInt(taskId, 10));
    if (selected) {
      setSelectedTask(selected);
      setProductName(selected.title || '');
      setBranch(selected.branch || 'Roydermal');
      setDevicesType(selected.devices_type || 'Mostra Tutto');
      setTags(selected.tags || []);
      setRefNumber(selected.ref_number || '');
    } else {
      setSelectedTask(null);
    }
  };

  const handleSave = () => {
    const dataToSave = {
      id: productData?.id,
      name: productName,
      releaseDate: productReleaseDate,
      tags,
      branch,
      devices_type: devicesType,
      ref_number: refNumber,
      inci_change: inciChange,
      graphic_change: graphicChange,
      inci_notes: inciNotes,
      graphic_notes: graphicNotes,
    };
    onSave(dataToSave);
    onClose();
  };

  return (
    <div className={`modal fade ${isOpen ? "show d-block" : ""}`} tabIndex="-1" style={{ background: isOpen ? "rgba(0, 0, 0, 0.5)" : "" }}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{productData ? 'Modifica Prodotto' : 'Aggiungi Prodotto'}</h5>
            <button type="button" className="btn-close" onClick={onClose}></button>
          </div>
          <div className="modal-body">
             {/* Mostra un messaggio se la modale è in modalità sola lettura */}
             {readOnly && (
              <div className="alert alert-warning">
                Questo prodotto è archiviato e non può essere modificato.
              </div>
            )}

            <div className="mb-3">
              <label htmlFor="taskSelector" className="form-label">Seleziona Task</label>
              <select className="form-select" value={selectedTask?.id || ''} onChange={(e) => handleTaskSelect(e.target.value)} disabled={readOnly}>
                <option value="">Seleziona una task...</option>
                {tasksInPrint.map((task) => (
                  <option key={task.id} value={task.id}>{task.title}</option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              <label className="form-label">Nome Prodotto</label>
              <input type="text" className="form-control" value={productName} onChange={(e) => setProductName(e.target.value)} disabled={readOnly} />
            </div>
            <div className="mb-3">
              <label className="form-label">Data di Ordine</label>
              <input type="date" className="form-control" value={productReleaseDate} onChange={(e) => setProductReleaseDate(e.target.value)}  disabled={readOnly} />
            </div>
            <BranchSelector value={branch} onChange={setBranch}  disabled={readOnly} />
            <DevicesTypeSelector value={devicesType} onChange={setDevicesType} disabled={readOnly}  /> 
            <TagInput selectedTags={tags} onTagsChange={setTags}  readOnly={readOnly}/>
            <div className="mb-3">
              <label className="form-label">REF Numero</label>
              <input type="text" className="form-control" value={refNumber} onChange={(e) => setRefNumber(e.target.value)} disabled={readOnly}  />
            </div>
            <div className="mb-3">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="inciChange"
                  checked={inciChange}
                  onChange={(e) => setInciChange(e.target.checked)} 
                  readOnly={readOnly}
                  disabled={readOnly} // Impedisci completamente l'interazione
                />
                <label htmlFor="inciChange" className="form-check-label">Cambio INCI</label>
              </div>
              {inciChange && (
                <textarea
                  className="form-control mt-2"
                  placeholder="Note per Cambio INCI"
                  value={inciNotes}
                  onChange={(e) => setInciNotes(e.target.value)}
                  readOnly={readOnly}
                  disabled={readOnly} // Impedisci completamente l'interazione
                ></textarea>
              )}
            </div>
            <div className="mb-3">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="graphicChange"
                  checked={graphicChange}
                  onChange={(e) => setGraphicChange(e.target.checked)}
                  readOnly={readOnly}
                  disabled={readOnly} // Impedisci completamente l'interazione
                />
                <label htmlFor="graphicChange" className="form-check-label">Cambio Grafica</label>
              </div>
              {graphicChange && (
                <textarea
                  className="form-control mt-2"
                  placeholder="Note per Cambio Grafica"
                  value={graphicNotes}
                  onChange={(e) => setGraphicNotes(e.target.value)}
                  readOnly={readOnly}
                  disabled={readOnly} // Impedisci completamente l'interazione
                ></textarea>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={onClose}>Chiudi</button>
            {!readOnly && (
              <button type="button" className="btn btn-primary" onClick={handleSave}>
                Salva
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductModal;

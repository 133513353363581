import React from 'react';

function DevicesTypeSelector({ value, onChange, disabled, isModal = false }) {
  const devicesTypes = [
    'Mostra Tutto',
    'Astuccio',
    'Campione',
    'Etichetta',
    'Foglietto',
    'Tubo',
    'Altro', // Sempre alla fine
  ];

  const getDisplayText = (type) => {
    if (isModal && type === 'Mostra Tutto') return 'Seleziona'; // Nella modale mostra "Seleziona"
    return type; // Altrimenti mostra il valore normale
  };

  return (
    <div className="mb-3">
      <label htmlFor="devicesTypeSelector" className="form-label">Seleziona Dispositivo</label>
      <select
        id="devicesTypeSelector"
        className="form-select"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled} // Aggiungi questa riga se vuoi anche disabilitarlo completamente
      >
        {devicesTypes.map((type) => (
          <option key={type} value={type}>
            {getDisplayText(type)}
          </option>
        ))}
      </select>
    </div>
  );
}

export default DevicesTypeSelector;

import React, { useEffect, useState } from "react";
import UrgentMessageModal from "./UrgentMessageModal";

function UrgentMessages() {
  const [urgentMessages, setUrgentMessages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false); // Stato per la modale

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/urgent-messages`)
      .then((response) => response.json())
      .then((data) => {
        console.log("Urgent messages fetched:", data); // Verifica il formato dei dati
        setUrgentMessages(data);
      })
      .catch((error) =>
        console.error("Error fetching urgent messages:", error)
      );
  }, []);

  const markAsRead = (messageId) => {
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/api/urgent-messages/${messageId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ is_read: true }),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Errore durante l'aggiornamento del messaggio.");
        }
        setUrgentMessages((prevMessages) =>
          prevMessages.map((message) =>
            message.id === messageId ? { ...message, is_read: true } : message
          )
        );
      })
      .catch((error) =>
        console.error("Errore durante l'aggiornamento del messaggio:", error)
      );
  };

  const saveUrgentMessage = (newMessage) => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/urgent-messages`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newMessage), // Invia un singolo oggetto
    })
      .then((response) => response.json())
      .then((data) => console.log("Urgent message saved:", data))
      .catch((error) => console.error("Error saving urgent message:", error));
  };

  const addMessage = (newMessage) => {
    setUrgentMessages((prevMessages) => [...prevMessages, newMessage]);
    saveUrgentMessage(newMessage); // Passa il singolo messaggio
  };

  const renderMessages = () => {
    if (!Array.isArray(urgentMessages)) {
      return <p>Errore: i messaggi non sono stati caricati correttamente.</p>;
    }

    const unreadMessages = urgentMessages.filter((message) => !message.is_read);

    if (unreadMessages.length === 0) {
      return <p>Nessuna comunicazione urgente.</p>;
    }

    return unreadMessages
      .sort((a, b) => b.pinned - a.pinned)
      .map((message) => (
        <li
          key={message.id}
          className={`list-group-item d-flex justify-content-between align-items-center ${
            message.pinned ? "list-group-item-warning" : ""
          }`}
        >
          <span>{message.message}</span>
          <button
            className="btn btn-sm btn-success"
            onClick={() => markAsRead(message.id)}
          >
            Letto
          </button>
        </li>
      ));
  };

  return (
    <div id="urgentMessagesContainer" className="container mt-4">
      <div className="container numb mt-4">
        <h5>Numeri Utili</h5>
        <div className="container mt-4">
          <div className="row">
            <div className="col number mt-4">
              Pasquale
              <br />
              200
            </div>
            <div className="col number mt-4">
              Contabilità
              <br />
              201
            </div>
            <div className="col number mt-4">
              Magazzino
              <br />
              202
            </div>
            <div className="col number mt-4">
              Magazzino 2<br />
              6201
            </div>
            <div className="col number mt-4">
              Grafica
              <br />
              203
            </div>
            <div className="col number mt-4">
              Direzione
              <br />
              204
            </div>
          </div>
        </div>
      </div>
      <br></br>
      <h5>Comunicazioni Urgenti</h5>
      <ul id="urgentMessagesList" className="list-group">
        {renderMessages()}
      </ul>
      <button
        className="btn btn-primary mt-3"
        onClick={() => setIsModalOpen(true)}
      >
        Aggiungi Messaggio Urgente
      </button>
      <UrgentMessageModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        addMessage={addMessage}
      />
    </div>
  );
}

export default UrgentMessages;

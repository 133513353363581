import React, { useState } from "react";

const UserSelector = ({ users, selectedUsers, onChange }) => {
  const [expandedGroups, setExpandedGroups] = useState({});

  const toggleGroup = (groupName) => {
    setExpandedGroups((prev) => ({
      ...prev,
      [groupName]: !prev[groupName],
    }));
  };

  const toggleUser = (user) => {
    const emails = user.email.split(',').map((email) => email.trim());
  
    // Verifica se tutte le email del gruppo/utente sono selezionate
    const allEmailsSelected = emails.every((email) =>
      selectedUsers.includes(email)
    );
  
    if (allEmailsSelected) {
      // Deseleziona tutte le email associate al gruppo/utente
      const newSelectedUsers = selectedUsers.filter(
        (email) => !emails.includes(email)
      );
      onChange(newSelectedUsers);
    } else {
      // Aggiungi tutte le email non ancora selezionate
      const newSelectedUsers = [...selectedUsers];
      emails.forEach((email) => {
        if (!newSelectedUsers.includes(email)) {
          newSelectedUsers.push(email);
        }
      });
      onChange(newSelectedUsers);
    }
  };

  // Filter out group users
  const individualUsers = users.filter(user => !user.isGroup);

  // Group the users
  const groupedUsers = individualUsers.reduce(
    (acc, user) => {
      const group = user.group || "Tutti";
      if (!acc[group]) acc[group] = [];
      acc[group].push(user);
      return acc;
    },
    {
      "Tutti": [],
      "Gruppo Grafica": [],
      "Gruppo Web": [],
      "Gruppo Magazzino": [],
    }
  );

  // Populate the "Tutti" group with individual users
  groupedUsers["Tutti"] = individualUsers;

  return (
    <div>
      <label className="form-label">Seleziona Utenti</label>
      {Object.keys(groupedUsers).map((groupName) => {
        const groupUsers = groupedUsers[groupName];
        const allGroupEmails = groupUsers.flatMap((user) =>
          user.email.split(',').map((email) => email.trim())
        );
        const allGroupEmailsSelected = allGroupEmails.every((email) =>
          selectedUsers.includes(email)
        );

        return (
          <div key={groupName} className="mb-2">
            {/* Group Header */}
            <div className="d-flex align-items-center">
            <input
                type="checkbox"
                className="form-check-input me-2"
                id={`group-${groupName}`}
                checked={allGroupEmailsSelected} // È vero solo se tutte le email del gruppo sono selezionate
                onChange={() => {
                    const newSelectedUsers = allGroupEmailsSelected
                    ? selectedUsers.filter(email => !allGroupEmails.includes(email)) // Deseleziona
                    : [...selectedUsers, ...allGroupEmails.filter(email => !selectedUsers.includes(email))]; // Seleziona
                    onChange(newSelectedUsers);
                }}
                />
              <label
                className="form-check-label"
                htmlFor={`group-${groupName}`}
                style={{ cursor: "pointer", fontWeight: "bold" }}
                onClick={() => toggleGroup(groupName)}
              >
                {groupName}
              </label>
              <button
                type="button"
                className="btn btn-sm btn-link"
                onClick={() => toggleGroup(groupName)}
              >
                {expandedGroups[groupName] ? "Nascondi" : "Espandi"}
              </button>
            </div>

            {/* Group Users */}
            {expandedGroups[groupName] && (
              <div className="ms-4">
                {groupUsers.map((user) => {
                  const emails = user.email
                    .split(',')
                    .map((email) => email.trim());
                  const allEmailsSelected = emails.every((email) =>
                    selectedUsers.includes(email)
                  );

                  return (
                    <div key={user.name} className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={`user-${user.name}`}
                        checked={allEmailsSelected}
                        onChange={() => toggleUser(user)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`user-${user.name}`}
                      >
                        {user.name}
                      </label>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default UserSelector;
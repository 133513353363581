function TaskTable({ title, tasks, onEdit, onArchive, onStatusChange, onPrintStatusChange, activeCategory = 'Pubblicitari' }) {
  console.log('Categoria attiva in TaskBoard:', activeCategory);

  const getPriorityClass = (priority) => {
    switch (priority) {
      case 1:
        return 'table-danger'; // Rosso chiaro
      case 2:
        return 'table-warning'; // Giallo
      case 3:
        return ''; // Nessuna classe, colore di default
      case 4:
        return 'table-info'; // Azzurro
      case 5:
        return 'table-light'; // Grigio chiaro
      default:
        return '';
    }
  };

  return (
    <div className="main-container mb-5">
      {/* Titolo della sezione (es. Da Fare, In Corso, ecc.) */}
      <h4 className="table-title">{title}</h4>

      <table className="table table-striped">
        <thead>
          <tr>
            <th>Nome</th>
            <th className="text-center">Priorità</th>
            {activeCategory !== 'Web' && activeCategory !== 'Dispositivi' && activeCategory !== 'Magazzino' && <th className="text-center">Stampa</th>}
            <th>Azioni</th>
          </tr>
        </thead>
        <tbody>
          {tasks.map((task) => (
            <tr key={task.id} className={getPriorityClass(task.priority)}>
              {/* Colonna Nome */}
              <td onClick={() => onEdit(task)} style={{ cursor: 'pointer' }}>
                {task.title}
              </td>

              {/* Colonna Priorità */}
              <td className="text-center">{task.priority}</td>

              {/* Colonna Stampa */}
              {activeCategory !== 'Web' && activeCategory !== 'Dispositivi' && activeCategory !== 'Magazzino' && (
              <td className="text-center">
                {task.print ? (
                  <i className="bi bi-check-circle-fill text-success"></i> // Icona spunta verde
                ) : (
                  <i className="bi bi-x-circle-fill text-danger"></i> // Icona croce rossa
                )}
              </td>
              )}

              {/* Colonna Azioni */}
              <td>
                {task.status !== 'done' && (
                  <select
                    className="form-select form-select-sm me-2"
                    value={task.status}
                    onChange={(e) => onStatusChange(task.id, e.target.value)}
                  >
                    <option value="todo">Da Fare</option>
                    <option value="in-progress">In Corso</option>
                    {/* Mostra "In Stampa" solo se la categoria NON è "Web" */}
                    {activeCategory !== 'Web' && <option value="in-print">In Stampa</option>}
                    <option value="done">Completato</option>
                  </select>
                )}
                {task.status === 'done' && (
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() => onArchive(task.id)}
                  >
                    Archivia
                  </button>
                )}
                {((activeCategory === 'Dispositivi' && task.status === 'in-print') || 
                  (task.print && task.status === 'in-print')) && (
                  <select
                    className="form-select form-select-sm"
                    value={task.print_status || ''}
                    onChange={(e) => onPrintStatusChange(task.id, e.target.value)}
                  >
                    <option value="ordered">Ordinato</option>
                    <option value="in-delivery">In Consegna</option>
                    <option value="received">Ricevuto</option>
                  </select>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TaskTable;
